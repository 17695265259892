import { userConstants } from '../_constants';
import { userService } from '../_services';
import { history, store } from '../_helpers';
import { error as toastError, success as toastSuccess } from 'react-toastify-redux';
import { authenticate, updateSession, getSessionData } from 'redux-simple-auth';
import i18n from 'i18next';

export const userActions = {
    logout,
    register,
    forgotPassword,
    changePassword,
    resetPassword,
    refreshToken,
    verifyEmail,
    toggleArchived,
    toggleSite,
    changeLanguage,
    registerPublic,
    sendVerificationCode,
    displayValidationRequest,
    resendValidationCode,
    reloadLocations,
    locate
};

function locate(address) {

    return userService.locate(address).then(res => {
        return res 
    });
};

function reloadLocations() {
    return dispatch => {
        dispatch(request());

        return userService.reloadLocations()
            .then(
                locations => {
                    store.dispatch(updateSession({ user: {
                        ...getSessionData(store.getState()).user, locations: locations.data
                    }}));
                },
                error => {
                    store.dispatch(failure(error));
                    if (error.response) {
                        store.dispatch(toastError(error.response.data));
                    }
                }
            );
    };

    function request() { return { type: userConstants.RELOAD_LOCATIONS_REQUEST } }
    function failure(error) { return { type: userConstants.RELOAD_LOCATIONS_FAILURE, error } }
}

function displayValidationRequest() {
    return { type: userConstants.DISPLAY_VALIDATION_REQUEST };
}

function resendValidationCode(email) {
    return dispatch => {
        dispatch(request(email));

        return userService.resendValidationCode(email)
            .then(
                user => {
                    dispatch(success());
                    dispatch(toastSuccess('A new validation email was sent. Please use it to confirm your email address.'));
                },
                error => {
                    dispatch(failure(error));
                    if (error.response.data) {
                        dispatch(toastError(error.response.data.error));
                    }
                }
            );
    };

    function request(user) { return { type: userConstants.RESEND_VALIDATION_CODE_REQUEST, user } }
    function success() { return { type: userConstants.RESEND_VALIDATION_CODE_SUCCESS } }
    function failure(error) { return { type: userConstants.RESEND_VALIDATION_CODE_FAILURE, error } }
}

function changeLanguage(language) {
    return { type: userConstants.CHANGE_LANGUAGE, language };
}

function toggleArchived() {
    return { type: userConstants.TOGGLE_ARCHIVED };
}

function toggleSite(siteId) {
    return { type: userConstants.TOGGLE_SITE, siteId };
}

function logout() {
    userService.logout()
    localStorage.clear();
    localStorage.setItem('i18nextLng', "en");

    return { type: userConstants.LOGOUT };
}

function register(user) {
    return dispatch => {
        dispatch(request(user));

        return userService.register(user)
            .then(
                user => {
                    dispatch(success());
                    history.push('/login');
                    dispatch(toastSuccess(i18n.t("Registration successful. If this email isn't already in use, instructions will be sent to validate your email address.")));
                },
                error => {
                    dispatch(failure(error));
                    if (error.response.data) {
                        dispatch(toastError(error.response.data.error));
                    }
                }
            );
    };

    function request(user) { return { type: userConstants.REGISTER_REQUEST, user } }
    function success() { return { type: userConstants.REGISTER_SUCCESS } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}

function registerPublic(user, form_actions) {
    return dispatch => {

        dispatch(request(user));

        return userService.registerPublic(user)
            .then(
                user => {
                    dispatch(authenticate("customAuthenticator", user.data.response.user))
                    dispatch(success());
                },
                error => {
                    dispatch(failure(error));
                    form_actions.setFieldError('email', error.response.data.error);
                    form_actions.setSubmitting(false);
                }
            );
    };

    function request(user) { return { type: userConstants.REGISTER_PUBLIC_REQUEST, user } }
    function success() { return { type: userConstants.REGISTER_PUBLIC_SUCCESS } }
    function failure(error) { return { type: userConstants.REGISTER_PUBLIC_FAILURE, error } }
}


function verifyEmail(emailVerificationCode) {
    return dispatch => {
        dispatch(request(emailVerificationCode));

        return userService.verifyEmail(emailVerificationCode)
            .then(
                user => {
                    dispatch(success());
                    history.push('/login');
                    dispatch(toastSuccess('Your email has been verified.'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(toastError(error));
                }
            );
    };

    function request(emailVerificationCode) { return { type: userConstants.VERIFY_EMAIL_REQUEST, emailVerificationCode } }
    function success() { return { type: userConstants.VERIFY_EMAIL_SUCCESS } }
    function failure(error) { return { type: userConstants.VERIFY_EMAIL_FAILURE, error } }
}

function forgotPassword(user) {
    return dispatch => {
        dispatch(request(user));

        return userService.forgotPassword(user)
            .then(
                user => {
                    dispatch(success());
                    history.push('/login');
                    dispatch(toastSuccess('If your account exists, you will receive instructions via email on how to reset your password.'));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(toastError(error.response.data.error));
                }
            );
    };

    function request(user) { return { type: userConstants.FORGOT_PASSWORD_REQUEST, user } }
    function success() { return { type: userConstants.FORGOT_PASSWORD_SUCCESS } }
    function failure(error) { return { type: userConstants.FORGOT_PASSWORD_FAILURE, error } }
}

function sendVerificationCode(user) {
    return dispatch => {
        dispatch(request(user));

        return userService.sendVerificationCode(user)
            .then(
                user => {
                    dispatch(success());
                    dispatch(toastSuccess('If your account exists, you will receive a verification code via email.'));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(toastError(error.response.data.error));
                }
            );
    };

    function request(user) { return { type: userConstants.SEND_VERIFICATION_CODE_REQUEST, user } }
    function success() { return { type: userConstants.SEND_VERIFICATION_CODE_SUCCESS } }
    function failure(error) { return { type: userConstants.SEND_VERIFICATION_CODE_FAILURE, error } }
}

function resetPassword(user) {
    return dispatch => {

        dispatch(request(user));

        return userService.resetPassword(user)
            .then(
                user => {
                    dispatch(success());
                    history.push('/login');
                    dispatch(toastSuccess('Successfully reset your password.'));
                },
                error => {
                    dispatch(failure(error));
                    if (error && error.response && error.response.data) {
                        dispatch(toastError(error.response.data.error));
                    }
                }
            );
    };

    function request(user) { return { type: userConstants.RESET_PASSWORD_REQUEST, user } }
    function success(user) { return { type: userConstants.RESET_PASSWORD_SUCCESS, user } }
    function failure(error) { return { type: userConstants.RESET_PASSWORD_FAILURE, error } }
}

function changePassword(user) {
    return dispatch => {

        dispatch(request(user));

        return userService.changePassword(user)
            .then(
                user => {
                    dispatch(success());
                    history.push('/');
                    dispatch(toastSuccess('Successfully reset your password.'));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(toastError(error.response.data.error));
                }
            );
    };

    function request(user) { return { type: userConstants.CHANGE_PASSWORD_REQUEST, user } }
    function success(user) { return { type: userConstants.CHANGE_PASSWORD_SUCCESS, user } }
    function failure(error) { return { type: userConstants.CHANGE_PASSWORD_FAILURE, error } }
}

function refreshToken(dispatch) {

    var freshTokenPromise = userService.fetchJWTToken(store)
        .then(user => {
            dispatch({
                type: userConstants.DONE_REFRESHING_TOKEN,
            });

            return user ? Promise.resolve(user) : Promise.reject({
                message: 'Could not refresh token.'
            });
        })
        .catch(e => {

            dispatch({
                type: userConstants.DONE_REFRESHING_TOKEN
            });
            return e;
        });

    dispatch({
        type: userConstants.REFRESHING_TOKEN,

        // we want to keep track of token promise in the state so that we don't try to refresh
        // the token again while refreshing is in process
        freshTokenPromise
    });

    return freshTokenPromise;
}
