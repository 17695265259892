import { authHeader, handleResponse } from '../_helpers';
import axios from "axios";
import fileDownload from 'js-file-download';

export const reportService = {
    get,
    physicianReport
};

function get() {
    const requestOptions = {
        headers: authHeader()
    };

    return axios.get('/api/reports', requestOptions);
    
}

function physicianReport(data) {
    const requestOptions = {
        headers: authHeader()
    };

    return axios.post('/api/physician_report', data, requestOptions);
    
}