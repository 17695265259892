/** Stripe publishable key */
//const STRIPE_PUBLISHABLE_KEY = "pk_test_51K8okdK14w53A8kqRkeEAQBJbJ8K7jf3CJAE2fDGKug4SMUs9YeGle4d9BFtDZG5WYFmIminUwVRCKkNNOqqc71s00XJA2mkDZ";
const STRIPE_PUBLISHABLE_KEY = "pk_live_51K8okdK14w53A8kq0EytlU6fzRTm3kTU9HG5SdeypniknATfaq5PxOdGqRNyhpM7cvGwmkQmEr2vuFV2m2Moneg000yDNfIu3n";


export const subscriptionConstants = {
    LOAD_REQUEST: 'LOAD_REQUEST',
    LOAD_SUCCESS: 'LOAD_SUCCESS',
    LOAD_FAILURE: 'LOAD_FAILURE',
    LOAD_REPORTS_LEFT_REQUEST: 'LOAD_REPORTS_LEFT_REQUEST',
    LOAD_REPORTS_LEFT_SUCCESS: 'LOAD_REPORTS_LEFT_SUCCESS',
    LOAD_REPORTS_LEFT_FAILURE: 'LOAD_REPORTS_LEFT_FAILURE',
    CLEAR_PORTAL: 'CLEAR_PORTAL',
    PORTAL_REQUEST: 'PORTAL_REQUEST',
    PORTAL_SUCCESS: 'PORTAL_SUCCESS',
    PORTAL_FAILURE: 'PORTAL_FAILURE',
    SUBSCRIBE_REQUEST: 'SUBSCRIBE_REQUEST',
    SUBSCRIBE_SUCCESS: 'SUBSCRIBE_SUCCESS',
    SUBSCRIBE_FAILURE: 'SUBSCRIBE_FAILURE',
    UNSUBSCRIBE_REQUEST: 'UNSUBSCRIBE_REQUEST',
    UNSUBSCRIBE_SUCCESS: 'UNSUBSCRIBE_SUCCESS',
    UNSUBSCRIBE_FAILURE: 'UNSUBSCRIBE_FAILURE',
    STRIPE_PUBLISHABLE_KEY: STRIPE_PUBLISHABLE_KEY,
};
