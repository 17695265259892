import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import XHR from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(XHR) // passes i18n down to react-i18next
  .init({
    detection: {
      order: ['localStorage'],
      lookupLocalStorage: 'i18nextLng',
      lookupFromSubdomainIndex: 0,
      caches: ['localStorage'],
      checkWhitelist: true
    },
    lngs: ["en","fr"],
    ns: [
      "translation"
    ],
    //backend: {
      // for all available options read the backend's repository readme file
      //loadPath: '/locales/{{lng}}/{{ns}}.json',
      //allowMultiLoading: true,
      //react: { wait: true }
    //},
    fallbackLng: {
      'en-US': ['en'],
      'fr-FR': ['fr'],
      'fr-CA': ['fr'],
      'en-CA': ['en'],
      default: ['fr'],
    },
    fallbackNS: "translation",
    keySeparator: false,
    nsSeparator: false,
    keySeparator: false, // we do not use keys in form messages.welcome
    debug: true,
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;
