import { subscriptionConstants } from '../_constants';
import { subscriptionService } from '../_services';
import { updateSession, getSessionData } from 'redux-simple-auth';
import { store } from '../_helpers';

export const subscriptionActions = {
    subscribe,
    load,
    portal,
    clearPortal,
    loadReportsLeft
};

function subscribe(cardElement, customerId, product, stripe) {
    return dispatch => {

        dispatch(request());
        return stripe
        .createPaymentMethod({
          type: 'card',
          card: cardElement,
        })
        .then((result) => {
          if (result.error) {
            return dispatch(failure(result.error));
          } else {
            return subscriptionService.subscribe(customerId, result.paymentMethod.id, product).then(
              (result) => {
                // Payment was successful.
                if (result.data.status === 'active') {
                  return dispatch(success(result));
                }
              }
            );
          }
        });
    };

    function request() { return { type: subscriptionConstants.SUBSCRIBE_REQUEST, cardElement, customerId, product } }
    function success(result) { return { type: subscriptionConstants.SUBSCRIBE_SUCCESS, result } }
    function failure(error) { return { type: subscriptionConstants.SUBSCRIBE_FAILURE, error } }
}

function load() {

  return dispatch => {

      dispatch(request());
      return subscriptionService.load().then(
            (result) => {
              return dispatch(success(result));
            }
          );
  };

  function request() { return { type: subscriptionConstants.LOAD_REQUEST } }
  function success(result) { return { type: subscriptionConstants.LOAD_SUCCESS, result } }
  function failure(error) { return { type: subscriptionConstants.LOAD_FAILURE, error } }
}

function portal() {

  return dispatch => {

      dispatch(request());
      return subscriptionService.portal().then(
            (result) => {
              return dispatch(success(result));
            }
          );
  };

  function request() { return { type: subscriptionConstants.PORTAL_REQUEST } }
  function success(result) { return { type: subscriptionConstants.PORTAL_SUCCESS, result } }
  function failure(error) { return { type: subscriptionConstants.PORTAL_FAILURE, error } }
}

function clearPortal() {
  return { type: subscriptionConstants.CLEAR_PORTAL }
}


function loadReportsLeft() {

  return dispatch => {

      dispatch(request());
      return subscriptionService.loadReportsLeft().then(
            (result) => {
              store.dispatch(updateSession({ user: {
                ...getSessionData(store.getState()).user, public_reports_left: result.data.public_reports_left
              }}));
            }
          );
  };

  function request() { return { type: subscriptionConstants.LOAD_REPORTS_LEFT_REQUEST } }
}