import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './_helpers';

import { Container } from './App/Container';
import { unregister } from './utils/registerServiceWorker';
import axios from "axios";
import 'react-perfect-scrollbar/dist/css/styles.css';
import './i18n';
require('dotenv').config();

unregister();

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.crossDomain = true;

ReactDOM.render(
  <Provider store={store}>
    <Container />
  </Provider>, document.getElementById('root'));
