import { reportConstants } from '../_constants';
import { reportService } from '../_services';
import { authenticate } from 'redux-simple-auth';

export const reportActions = {
    get,
    physicianReport
};

function get() {
    return dispatch => {
        dispatch(request());

        return reportService.get()
            .then(
                reports => dispatch(success(reports.data))
            )
            .catch(
                (error) => {
                    dispatch(failure(error.response.data.error));
                }
            );
    };

    function request() { return { type: reportConstants.GETALL_REQUEST } }
    function success(reports) { return { type: reportConstants.GETALL_SUCCESS, reports } }
    function failure(error) { return { type: reportConstants.GETALL_FAILURE, error } }
}

function physicianReport(data) {
    return dispatch => {
        dispatch(request(data));

        return reportService.physicianReport(data)
            .then(
                reports => {
                    dispatch(success(reports))
                    if (reports && reports.data && reports.data.response && reports.data.response.user) {
                        dispatch(authenticate("customAuthenticator", reports.data.response.user))
                    }
                }
            )
            .catch(
                (error) => {
                    dispatch(failure(error.response.data.error));
                }
            );
    };

    function request() { return { type: reportConstants.GET_PHYSICIAN_REQUEST, data } }
    function success(reports) { return { type: reportConstants.GET_PHYSICIAN_SUCCESS, reports } }
    function failure(error) { return { type: reportConstants.GET_PHYSICIAN_FAILURE, error } }
}