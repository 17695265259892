import { userConstants } from '../_constants';

export function authentication(state = {}, action) {
  switch (action.type) {
    case userConstants.REGISTER_REQUEST:
      return { registering: true };
    case userConstants.SEND_VERIFICATION_CODE_REQUEST:
      return { 
        codeSent: false,
        sending: true  
      };
    case userConstants.SEND_VERIFICATION_CODE_SUCCESS:
      return { 
        codeSent: true,
        sending: false  
      };
    case userConstants.SEND_VERIFICATION_CODE_FAILURE:
      return { 
        codeSent: false,
        sending: false  
      };
    case userConstants.DISPLAY_VALIDATION_REQUEST:
      return { displayValidation: true };
    case userConstants.RESEND_VALIDATION_CODE_SUCCESS:
      return { displayValidation: false };
    case userConstants.REGISTER_SUCCESS:
      return {};
    case userConstants.REGISTER_FAILURE:
      return {};
    case userConstants.REFRESHING_TOKEN:
      return { refreshing: true };
    case userConstants.DONE_REFRESHING_TOKEN:
      return { refreshing: true };
    default:
      return state
  }
}
